html {
  font-size: 62.5%; /* 16px * 62.5 = 10px = 1rem */
  box-sizing: border-box;

}

*, *:before, *:after {
  box-sizing: inherit;
}

/*GLOBALES*/
/**FONTS***/
@import url('https://fonts.googleapis.com/css2?family=Passion+One:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Changa+One:ital@1&display=swap');
@font-face{
  font-family: "segoe-ui";
  font-style: italic;
  src: local("segoe-ui.otf");
}

  #root {
    height: 100%;
  }
  body {
    background-color:#091335;
    text-align: center;
    margin: 0;
    padding: 0;
  

    }
    /* @media (min-width:800px){
      body {
        margin-right: 20px;
        margin-left: 20px;
        padding: 0;
      
    
        }

    }     */
   

h1 {
  display: flex;
    color: red;
    font-size: calc(16px + 6 * ((450vw - 320px) / 680));
    margin-top: 1rem;
    font-family: 'Passion One', cursive;
    justify-content: center;
  
}
h2 {
    color:orange;
    text-align: left;
    font-size: calc(15px + 6 * ((250vw - 320px) / 680));
    font-family: 'Passion One', cursive;
    padding-left: 1rem;

 
  }
  h3{
    color:white;
    text-align: center;
    font-size: 2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;

  }
  h4{
    color:white;
    text-align: center;
    font-size: 3.5rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  h5{
    color:white;
    text-align: center;
    font-size: calc(10px + 6 * ((250vw - 320px) / 680));
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding-left: 1rem;

  }

  p{
    color:white;
    font-size: 1.5rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

  }

  hr{
    border: 0;
    height: 0;
    border-top: 1px solid #8c8c8c;
  }
  ol{
    text-align: left;
    display:inline-block;
    margin-left: 0.5rem;
  }

  /*HERRAMIENTAS*/
  .centrar{
    text-align: center;
  }
  .full-width {
    width: 100%;
  }
  .color-orange{
    color: orange;
  }
  .color-red{
    color: red;
  }
  .color-white{
    color: white;
    margin-bottom: 0.1rem;
  }
  .container-sello{
    width:95%;
    max-width:900px;
    padding:32px 64px;
    margin:auto;
  }
  .sello{
    width:90px;
			position:fixed;
			bottom:5px;
			left:0;
  }
  .sello2{
    width: 55px;
    position: fixed;
    bottom: 14px;
    right: 2px;
  }
  /*Botones*/

  .button-red{
    font-size: calc(16px + 6 * ((4vw - 320px) / 680));
    background-color:red;
    border: red;
    border-radius: 1rem;
    color: white;
    cursor: pointer;
    font-family: 'Muli', sans-serif;
    width:11rem;
    height: 4rem;
    margin: 0 auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   
  }
  .button-transparente{
    font-size: calc(16px + 6 * ((4vw - 320px) / 680));
    background-color: transparent;
    color: black;
    border: solid red;
    border-radius:1rem;
    color: white;
    cursor: pointer;
    width:11rem;
    height: 4.1rem;
    margin: 0 auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  
  
  }
  .button-red-promociones{
    font-size: calc(16.5px + 6 * ((4vw - 320px) / 680));
    background-color:red;
    border: red;
    border-radius: 1rem;
    color: white;
    cursor: pointer;
    font-family: 'Muli', sans-serif;
    width:15rem;
    height: 4.1rem;
    margin: 0 auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   
  }
  .button-transparente-promociones{
    font-size: calc(16px + 6 * ((4vw - 320px) / 680));
    background-color: transparent;
    color: black;
    border: solid red;
    border-radius:1rem;
    color: white;
    cursor: pointer;
    width:15rem;
    height: 4.1rem;
    margin: 0 auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  
  
  }
  .button-transparente-ac{
    font-size: calc(16px + 6 * ((4vw - 320px) / 680));
    background-color: transparent;
    color: black;
    border: solid red;
    border-radius:1rem;
    color: white;
    cursor: pointer;
    width:11rem;
    height: 4.1rem;
    margin: 0 auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
  
  
  }
  

  .button-red-forms{
    font-size: calc(16px + 6 * ((10vw - 320px) / 680));
    background-color:red;
    border: red;
    border-radius: 1rem;
    color: white;
    cursor: pointer;
    width:30rem;
    height:4rem;
    margin: 0 auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  .button-transparente-forms{
    font-size: calc(16px + 6 * ((10vw - 320px) / 680));
    background-color: transparent;
    color: black;
    border: 2px solid red;
    border-radius: 1rem;
    color: white;
    cursor: pointer;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width:30rem;
    height:4rem;
    margin: 0 auto;
    text-decoration: none;
   

  }
  .button-transparente-niveles{
    font-size: calc(16px + 6 * ((10vw - 320px) / 680));
    background-color: transparent;
    color: black;
    border: 2px solid red;
    border-radius: 1rem;
    color: white;
    cursor: pointer;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width:20vw;
    margin: 0 auto;
  }
 
  @media (min-width:800px){
    .button-red{
      font-size: calc(16px + 6 * ((4vw - 320px) / 680));
      background-color:red;
      border: red;
      border-radius: 1rem;
      color: white;
      cursor: pointer;
      font-family: 'Muli', sans-serif;
      width:20rem;
      height: 4rem;
      margin: 0 auto;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
     
    }
    .button-transparente-ac{
      font-size: calc(16px + 6 * ((4vw - 320px) / 680));
      background-color: transparent;
      color: black;
      border: solid red;
      border-radius:1rem;
      color: white;
      cursor: pointer;
      width:20rem;
      height: 4.1rem;
      margin: 0 auto;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
    
    
    }
    .button-red-promociones{
      font-size: calc(16px + 6 * ((4vw - 320px) / 680));
      background-color:red;
      border: red;
      border-radius: 1rem;
      color: white;
      cursor: pointer;
      font-family: 'Muli', sans-serif;
      width:20rem;
      height: 4.1rem;
      margin: 0 auto;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
     
    }
    .button-transparente-promociones{
      font-size: calc(16px + 6 * ((4vw - 320px) / 680));
      background-color: transparent;
      color: black;
      border: solid red;
      border-radius:1rem;
      color: white;
      cursor: pointer;
      width:20rem;
      height: 4.1rem;
      margin: 0 auto;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    
    
    }

  }

  /* LINKS*/
  

  
  .header-links a {
    color: #ffffff;
    text-decoration: none;
    padding: 1rem;
  }
  .header-links a:hover {
    color:gray;
  }
  
  a {
    text-decoration: none;
    color:rgb(82, 82, 165);
  }
  a:hover {
    color:#ffffff;
    text-decoration: none;
  }
  .link-name-botones{
    text-decoration: none;
  }
  /***FILTRO Y BUSCAR***/
/* Filter */
.container-filter{
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;

}




.filter {
  display: flex;
  list-style-type: none;
  padding: 0;
  justify-content: space-between;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 2.5rem;
  margin-right: 1rem;
  margin-left: 1rem;
 
}
.filter-1 {
  display: flex;
  list-style-type: none;
  width: 15rem;
  height: 3rem;
  justify-content: space-between;
  align-items: center;
  
 
}

/* .lupa{
  background-image:url(/imagenes/lupa2.png);
  background-repeat: no-repeat;
  width: 0.5rem;
} */
.filter input,
.filter button {
  
  border-radius: 1rem;
  border: 0.1rem white;
  font-size: 1.6rem;
  background-color:whitesmoke;
  height: 3.5rem;
  width:15rem;
}
.filter select {
  
  border-radius: 1rem;
  border: 0.1rem white;
  font-size: 1.6rem;
  background-color:whitesmoke;
  height: 3.5rem;
  width:30rem;
}
.search{
  display: flex;
  background-color: whitesmoke;
  border-radius: 1rem;
  height: 3.5rem;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
}
.search2{
  display: flex;
  background-color: whitesmoke;
  border-radius: 1rem;
  height: 3.5rem;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  width: 80%;
  align-items: center;
  margin-left: 4rem;
  margin-bottom: 2rem;
}

option{
  color: white;
  background-color: #0C193D
}


@media (min-width:820px){
.search2{
  display: flex;
  background-color: whitesmoke;
  border-radius: 1rem;
  height: 3.5rem;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  width: 93%;
  align-items: center;
  margin-left: 4.5rem;
  margin-bottom: 2rem;
}
}




@media (min-width:600px){
  .filter {
    justify-content: space-evenly;
  }

  .filter input{
    width:30rem;
    
  }

  filter select{
    width:30rem;
    
  }
.filter-1{
  justify-content: center;
  border-radius: 1rem;
  border: 0.1rem ;
  font-size: 1.6rem;
  background-color:whitesmoke;
  height: 3.5rem;
  width:15rem;
  display: flex;
  align-items: center;
  margin:4rem;
  width: 30rem;
}



}

.filter input,
.filter button {
  margin-right: 2rem;

}
.filter select {
  margin-right: 2rem;

}


  /*NAVEGACION*/
.nav2{
  display: flex;
  justify-content: center;
  align-items: center;
}
.redes-sociales-fac{

  height: 43px;
  width: auto;
}


@media (min-width:700px){
  .nav1{
    display: none;
  }
}
@media (max-width:701px){
  .nav2{
    display: none;
  }

}


  nav{
    width: 100%;
    background-color: #0C193D;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.5)
  }
  
  .nav1{
    background: transparent;
    height: 80px;
    color:#fff;
  }
  .nav3{
    background: transparent;
    height: 55px;
    color: #fff;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
 
  }
  .saludo{
    display: flex;
  }
  .profile{
    position: absolute;
    left: 0.5px;
    top: 0.5px;
    
  }



  
  .contenedor-nav{
    display: flex;
    margin: auto;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    height:inherit;
    overflow: hidden;
  }
 
  nav .enlaces a:hover{
    border-bottom: 3px solid #1498a4;
    transition: 0.6s;
  }

  
  .logo, .logo img{ height:60px; }
  
  .icono{
    display:block;
    border: none;
    font-size: 0.4rem;
    background:none;
    color: white;
    justify-content: center;
  }

  .icon-espacio{
    display: flex;
    left: 0;
    height: 40px;
    margin-right: 1rem;
    margin-top: 0.4rem;
 
  }
  .logo-navbar{
    width: 12.5rem;
    display:flex;
    border: none;
    background:none;

    justify-content:right;

  }
  .logo-atras{
    width: 4rem;
    height: 3rem;
    margin-right: 3rem;
  }
  .sesion{
    display: flex;
    justify-content: center;
    background: none;
    border: none;
    align-items: center

  }
  .icon-logo{
   
    height: 50px;
    width: auto;
    
  }


  .logo-nav{
    display:flex;
    justify-content: center;
    justify-items: center;
    width: 6rem;


  }
  
  .boton-atras{
    display: flex;
    justify-content: center;
    background: none;
    border: none;
    width: 3.5rem;
  }
  .mi-espacio{
    display: flex;
    justify-content: center;
    margin-left: 2.2rem;
    background: none;
    border: none;
  }
  .mi-espacio-md{
    display: flex;
    justify-content: center;
    background: none;
    border: none;
    align-items: center;
    margin-left: 0;
  }

  .logo-nav-2-redes{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 5rem

  }
  .logo-nav-2-carrito{
    display: flex;
    justify-content: flex-end;
    align-items: center;


  }

  

  
  @media screen and (max-width: 700px){


    .icono{
      display:block;
      font-size:1.9rem;
      padding: 2px;
      background:none;

    }
    .logo-navbar{
      width: 15vh;


    }
    .logo-nav{
      display:flex;
      justify-content: center;
      justify-items: center;
      width: 6rem;
      margin-left: 6rem;
      margin-right: 3rem;
 
    }
    .logo-nav-1{
      display: flex;
      justify-content: center;
      margin-left:0;
      background: none;
      border: none;

    }

    .logo-nav-2{
      display: flex;
      justify-content: flex-end;
      align-items: center;

    }
    .logo-nav-2-redes{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 0.5rem

    }
    .boton-flecha{
      display: flex;
      justify-content: center;
      background: none;
      border: none;
      width: 5rem;
    }
 
   
    :root{
      --margenes: 30px;
    }
  }
  /**Navegacion2**/
  nav{
    width: 100%;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.5)
  }
  
  .nav1-2{
    background: transparent;
    height: 80px;
    color:#fff;
  }
  .logo-nav-700{
    display:flex;
    justify-content: center;
    justify-items: center;
    width: 6rem;


  }
  .md{
    margin-right: 15rem;
  }

  

  
  .contenedor-nav-2{
    display: flex;
    margin: auto;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    height:inherit;
    overflow: hidden;
  }

  nav .enlaces a:hover{
    border-bottom: 3px solid #1498a4;
    transition: 0.6s;
  }
  
  .logo, .logo img{ height:60px; }
  
  .icono-2{
    display:flex;
    border: none;
    background:none;
    color: white;
    justify-content:right;
    
  }

  
  @media screen and (max-width: 700px){


    .icono{
      display:block;
      font-size: 2.6rem;
      padding: 3px;
      background:none;

    }
  
 
   
    :root{
      --margenes: 30px;
    }
    
  }
  /*FORMULARIOS*/
  .button-azul-redes{
    display: flex;
    justify-content: center;
    font-size: calc(16px + 6 * ((10vw - 320px) / 680));
    background-color: rgb(58, 58, 168);
    border: red;
    border-radius: 1rem;
    color: white;
    cursor: pointer;
    width: 30rem;
    height: 4rem;
    margin: 0 auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 1rem;
  }
  .button-rojo-redes{
    font-size: calc(16px + 6 * ((10vw - 320px) / 680));
    background-color:red;
    border: red;
    border-radius: 1rem;
    color: white;
    cursor: pointer;
    width:30rem;
    height:4rem;
    margin: 0 auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    
  }
  .button-transparente-redes{
    font-size: calc(16px + 6 * ((10vw - 320px) / 680));
    background-color: transparent;
    color: black;
    border: 2px solid red;
    border-radius: 1rem;
    color: white;
    cursor: pointer;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width:30rem;
    height:4rem;
 

  }
  .redes-sociales-gmail{
    max-width: 2rem;
    max-height: 2rem;
    margin-right: 1rem;


  }
  .redes-sociales-facebook{
    max-width: 4rem;
    max-height: 4rem;
    

  }
  input[type=checkbox] {
    width:20px;
    height:20px;
    margin-left: 1rem;
    color: white;

  }


  .input{
  
    width:30rem;
    height:4rem;
 
      padding:20px 20px 20px 20px;
      border: white;
      border-radius: 10px;
      margin-bottom: 20px;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

      }
      .checkbox{
        margin-right: 12px;
        display: flex;
    justify-content: center;
      }
      .comentarios{
        padding: 25px 25px 25px 25px;
        border: white;
        border-radius: 20px;
        margin-bottom: 20px;
    
      }

      
     

  @media (min-width: 700px){
    .input{
      width:30rem;
      height:4rem;

      justify-content: center;


    }
    .username {

      justify-content: center;

    }
    .datos-forms{
     display: flex; 
     flex-direction: column;
     justify-content: center;
     align-items: center;
    }

  }
  .datos-forms{
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
   }

  .App-logo{
    max-width: 15rem;
    max-height: 15rem;
  }
  .container-login-form{
    display: flex;
    flex-direction: column;

  }

  .container-login-perfil{
    display: flex;
    flex-direction: column;
    margin-left: 3rem;
  }
  .container-login-perfil-md{    
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }
  @media (min-width:500px){
    .container-login-form{

      padding: 5px 5px 5px;
    }
  }

  .container-login-form-orden{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    border-radius: 20px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    color: black;
    flex-direction: column;
    
    width: calc(197% / 2);
    margin: auto;
  }


  
  @media screen and (min-width: 894px){
    .container-login-form{
      display: flex;
      justify-content: center;
      border: 2px solid white;
      border-radius: 20px;
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
      color: black;
      flex-direction: column;
      padding-right: 5px;
      padding-left: 5px;
      padding-bottom: 40px;
      width: calc(70% / 2);
      margin: auto;
    }
    .container-login-form-orden{
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid white;
      border-radius: 20px;
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
      color: black;
      flex-direction: column;
      
      width: calc(110% / 2);
      margin: auto;
    }
    .contenedor-redes-sociales{
      display:flex;
      flex-direction: row;
     justify-content: space-between;


     }
  }
  

  .username {
    display: flex;
    font-size: 1.6rem;
    color: white;
    justify-content: right;
    padding-left:25px;
    padding-right:25px;
    padding-top:25px ;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
    

    

    
    .login-recuerdame{
      color: white;
      display: flex;
      font-size: 1.6rem;
      margin-top: 2px;
      padding:50px 50px 50px 50px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  

     
    }
    
    .login-olvidaste{
      color: white;
      display: flex;
      justify-content: center;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-size: 1.5rem !important;
      margin-bottom: 5rem;
      margin-top: 2.5rem;
      text-decoration: underline;
    }
    
    .login-register {
      padding-top: 4rem;
      font-size: 1.5rem;
    }
    
    .login-register a:hover {
      text-decoration: underline;
    }
    .contenedor-redes-sociales{
      display:flex;
      justify-content: space-around;
      margin-top: 3rem 3rem 3rem 3rem;

     }

    

    
    ::placeholder {
      color: grey;
      font-size: 15px;
      
    }
  /*FOOTER*/

  .site-footer{
    background-color: #0C193D;
    font-size: 1.6rem;
    color: #ffffff;
    display: flex;
    justify-content: center;
    height: 10rem;
    bottom: 0;
    margin-top: 5rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.footer{
 
  margin-top: 2.5rem;
}
.footer-1{
  margin-top:1.5rem;
}

/***HOME PRINCIPAL**/
.fondo-cancha{
  background: rgb(43,62,138);
  background: linear-gradient(0deg, rgba(43,62,138,1) 33%, rgba(11,19,48,1) 99%);
}

.banner{
  padding-left: 2rem;
  padding-right: 2rem;

  justify-content: center;
  max-width: 90%;
  min-width: 90%;
  max-height: 30rem;
  
}

.container-juego{
  padding: 1rem;
}

.container-menu-redondos{

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-bottom: 5rem;
  margin-right: 0;
  margin-left: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.iconos-redondos{
  display: flex;
  width: 10.5rem;
  height: 10.5rem;
  justify-content: space-evenly;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 20px;
 
}
.iconos-redondos-1{
  display: flex;
  width: 13rem;
  height: 13rem;
  justify-content: space-evenly;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 0.5rem
 
}
.icon-logo-c{
  height: 30px;

}
.nuestro-juego{
  display: inline;
  /* margin-left: 3rem; */
  /* margin-right: 3rem; */
   padding: 1rem; 
  /* text-align: left; */
  text-align: initial;
  line-height: 2.5rem;

}

@media (min-width: 700px){
  .iconos-redondos{
    display: flex;
    width:15rem;
    height: auto;
    margin-right: 5rem;
    margin-left: 5rem;
   
  }
  .iconos-redondos-1{
    display: flex;
    width:20rem;
    height: auto;
   
    margin-right: 5rem;
    margin-left: 5rem;

   
  }
  .container-menu-redondos{

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 5rem;
    margin-right: 0;
    margin-left: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }


    .img-testimonial{
      width: 100%;
      margin-left: 5rem;
      margin-right: 5rem;
      height: 80px;
    
    
    }
    .testimonial {
      width: 90%;
      height: auto;
      background-color: rgba(65, 62, 62, 0.582);
      justify-content: center;
      align-items: center;
      border-radius: 2rem;
      margin: 2rem;
      z-index: 20;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      padding-bottom: 1rem;
      padding-left: 1.3rem;
      padding-top: 0.1rem;
      
    
    }

    
}
.testimonio-f{
  display:flex;
  justify-content: flex-end;
  font-size: 1rem;
  margin-right: 2rem;
}
.texto-t{
  margin: 0.1rem
}

.App-logo-principal{
  max-width: 7rem;
  max-height: 7rem;

}
.img-testimonial{

  height:28rem;

}

.testimonial {
  width: 90%;
  height: auto;
  background-color: rgba(65, 62, 62, 0.452);
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  margin: auto;
  margin-top: 6rem;
  z-index: 20;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding-bottom: 1rem;
  padding-left: 1.3rem;
  padding-top: 0.1rem;
  

}






.testimonial blockquote {
  display: block;
  font-size: 2rem;
  justify-content: center;
  font-weight: 600;
  color: #ffffff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
  
  /* Products */
  .promociones{
    background-color: #659639;


  }


 
  .carrusel{
    list-style-type: none;

  }

  .products {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-left: 50px;
    padding-right:50px;
    padding-bottom: 10px;
    position: relative;
    
    
  }
 
 
  .products li {
    list-style-type: none;
    padding: 0;
    flex: 0 1 34rem;
    margin: 1rem;
    height: 100%;
    border-bottom: 0.1rem #c0c0c0 solid;

  }
  .product {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
   
  }
  .product-name {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    height: 6rem
    
    
  }

  .product-brand {
    font-size: calc(16px + 6 * ((8vw - 320px) / 680));
    color:white;
    margin-left: 1rem;
    margin-right: 1rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  
    
  }
  .product-price {
    font-size: calc(16px + 6 * ((20vw - 320px) / 680));
    font-weight: bold;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    line-height: 2.5rem;
   
    
  }
  .product-price-t {
    font-size: calc(16px + 6 * ((20vw - 320px) / 680));
   
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 3rem;
    padding: 0.5rem;
   
    
  }

  @media (min-width: 700px){
    .product-image {

      border-radius: 10px;
      display: flex;
      width: 20rem;
      height: 20rem;
      margin-right: 1rem;
      margin-left: 1rem;
  }
  
    

  }

  .product-image {

    border-radius: 10px;
    display: flex;
    width: 20rem;
    min-width: 2rem;
    height: 10rem;
    margin-right: 1rem;
    margin-left: 1rem;

  }
  .link-name{
    color: white;
    display: flex;
    justify-content: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.3rem !important;
    text-decoration: underline;
  }
  .product-price div,
  .product-price button{
    text-align: center;
  }
  .product-price div{
    font-size: 2rem;
  }

/* Rating */
.rating span {
  color: #ffb901;
  font-size: 1.8rem;
  margin: 0.1rem;
}
.rating span:last-child {
  color:white;
  font-size: 1.4rem;
}
a > .rating > span:last-child {
  color: white;
}
rating{
  display: flex;
}
.filter-comentarios{
  display: flex;
  /* list-style-type: none; */
  padding: 0;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 2.5rem;

  border-radius: 1rem;
  border: 0.1rem white;
  font-size: 1.6rem;
  background-color: whitesmoke;
  height: 4rem;
  width: 30rem;
}
@media (min-width: 700px){
  .filter-comentarios{
    display: flex;
    /* list-style-type: none; */
    padding: 0;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    margin-bottom: 2.5rem;
    margin-right: 7rem;
    margin-left: 7rem;
    border-radius: 1rem;
    border: 0.1rem white;
    font-size: 1.6rem;
    background-color: whitesmoke;
    height: 4rem;
    width: 30rem;
  }
}


.reviews-name{
  display: flex;
  justify-content: center;
  /* background-color: rgba(255,255,255,0.1); */
  /* border: 2px solid white; */
  border-radius: 20px;
  box-shadow: 0 14px 10px 10px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  /* color: black; */
  flex-direction: column;
  padding: 1px 1px 1px 1px;
  width: calc(170% / 2);
  margin: auto;
  margin-bottom: 2rem
}

/*** homeCarrito****/

.product-details img {
  height:17rem;
  width:80%;
  margin-top: 5rem;

}
.product-details-description {
  flex: 1 1;
  margin: 1rem;
  display: flex;
 flex-direction: column;
 font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
 text-align: justify;
 justify-content: center;
 padding-left: 2rem;
 padding-right: 2rem;
}
.product-details-actions{
  margin: 2rem;
  padding: 2rem;
  display: flex;
 flex-direction: column;
}
.product-details-actions2{
  margin: 2rem;
  /* padding: 2rem; */
  display: flex;
  flex-direction: column;
}

.product-price-details{
  font-size: 1.9rem;
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 2.5rem;
}

.product-total-order{
font-size: 1.9rem;
color: white;
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
display: flex;
justify-content: space-around;
}



/* Product Details */
.my-modal{
  background-color: #091335;
  height: 100%;
  overflow: auto;

}
.price{
  font-size: 3rem;
}
.details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 1rem;
}


.text-center {
  text-align: center;
}

.product-details img {
   height: 50%;
   width: 50%;
  }
.close-modal{
  display: flex;
  font-size: 30px;
  color:white;
  margin-left: 85%;
  cursor: pointer;
  width:5rem; 
  background: none;
  border: none;
}


.close-modal-cart{
  display: flex;
  font-size: 30px;
  color:white;
  margin-left: 85%;
  cursor: pointer;
  width:5rem; 
  background: none;
  border: none;
}


  /* Sidebar */

      
    
  .sidebar-close-button {
    font-size: 4rem;
    padding: 0.5rem;
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
    display: block;  
  }
  .close {
    position: absolute;
    right: 10px;
    top: 8px;
    width: 32px;
    height: 32px;
    background: none;
    border: none;

  }

  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 30px;
    width: 2px;
    background-color: white;
  }
  .close:before {
    transform: rotate(45deg);
    background-color: white;
  }
  .close:after {
    transform: rotate(-45deg);
    background-color: white;
  }
  

  .sidebar {
    position: absolute;
    top:120px;
    background:rgb(17, 17, 68);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    border: solid 1px;
    border-radius: 10px 40px 40px 10px;
    left: 0;
    height: 80%;
    transition: 1s;
    overflow: hidden;
    transition: all 0.5s;
    transform: translateX(-40rem);
    width: 30rem;
    z-index: 20;
   


  }
  
  .sidebar-CART {
    position: absolute;
    top:120px;
    background:rgb(17, 17, 68);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    border: solid 1px;
    border-radius: 10px 40px 40px 10px;
    left: 0;
    height: 200%;
    transition: 1s;
    /* overflow: hidden; */
    transition: all 0.5s;
    transform: translateX(-40rem);
    width: 30rem;
    z-index: 20;
   


  }


  @media (min-width: 800px){
    .sidebar-CART {
      position: absolute;
      top:120px;
      background:rgb(17, 17, 68);
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
      border: solid 1px;
      border-radius: 10px 40px 40px 10px;
      left: 0;
      height: 200%;
      transition: 1s;
      overflow: hidden;
      transition: all 0.5s;
      transform: translateX(-60rem);
      width: 60rem;
      z-index: 20;
     
  
  
    }
    .close-modal-cart{
      display: flex;
      font-size: 30px;
      color:white;
      margin-left: 90%;
      cursor: pointer;
      width:5rem; 
      background: none;
      border: none;
    }

  }


  .sidebar-CART.open {
    transform: translateX(0);
  
  }
  .icono-cart{
    display: flex;
    justify-content: center;
    background: none;
    border: none;
    width: 5rem;
    
  }
  .icono-cart-700{
    display: flex;
    justify-content: center;
    background: none;
    border: none;
    width: 5rem;


  }
  .carrito-1{
    width: auto;
    height: 3.2rem;
    border: none;
    background:none; 
  }

  .carrito{
  
    height: 3.2rem;

    
  }
  .sidebar.open {
    transform: translateX(0);
  
  }
  
  .sidebar-close-button {
    border-radius: 45%;
    border: none;
    width: 3rem;
    height: 3rem;
    padding: 0.5rem;
    font-size: 2rem;
    padding-top: 0;
    cursor: pointer;
    position: absolute;
    right: 0.5rem;
    top: 1.5rem;
  }
.button-red-carrito{
  font-size: calc(16px + 6 * ((15vw - 320px) / 680));
  background-color:red;
  border: red;
  border-radius: 1rem;
  color: white;
  cursor: pointer;
  width:30vh;
  height: 4.1rem;
  margin: 0 auto;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


  

  .categories {

    font-size: 1rem;
    color: rgba(255, 255, 255, 0.8);
    font-family: "Arial", sans-serif;

  
  }
  .categories a {
    display: flex;
    padding: 3rem 0 0 0;

  }
  
  .categories a:hover {
    background-color:rgb(21, 21, 71);

  }

  .redes-sociales{
    height: 30px;
    width: auto;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    

  }
  .redes-s{
    height: 44px;
    width: auto;
    margin-bottom: 1rem;
    margin-right: 0.6rem
  }
  .redes-s-salir{
    height: 38px;
    width: auto;
    margin-right: 1.1rem

  }
  .redes-s-salir2{
    height: 29px;
    width: auto;
    margin-right: 1.1rem

  }
  .mis-datos-110{
    height: 34px;
    width: auto;
    margin-right: 1.1rem
  }
  .iniciar-sesion{
    height: 50px;
    width: auto;
    margin-top: 2.5rem;
  }
  .error{
    margin: 1.5rem;
    color: white;
    font-size: 1.5rem;
    background-color: black;
    padding: 0.6rem;
    box-shadow: 5px 5px 5px black;
  }
  .lista-sidebar{
    display: -webkit-box;
    justify-content: space-between;
    justify-items: center;
    font-family: "Arial", sans-serif;
    height: 6.5rem;
    margin-bottom: 1rem;
}
.lista-sidebar-700{
  display: -webkit-box;
  justify-content: space-between;
  justify-items: center;
  font-family: "Arial", sans-serif;
  height: 6.5rem;
  margin-top: 2rem;
  margin-right: 2rem;

}

.lista-sidebar-700-iz{
  display: -webkit-box;
  justify-content: space-between;
  justify-items: center;
  font-family: "Arial", sans-serif;
  height: 6.5rem;
  margin-top: 2rem;
  margin-right: 2rem;
}
  .menu{
    margin-top: 1rem;
  }
/*** MEJORA TU RENDIMIENTO Y APOYANOS MENU ***/
.contenedor-rendimiento{
  display: flex;
  justify-items: center;
  margin: 1rem 1rem 1rem 1rem;
  flex-direction: column;
  
}
.texto-rendimiento{
  font-size: calc(16px + 6 * ((15vw - 320px) / 680));
  color:white;
  
}
.texto-rendimiento2{
  font-size: calc(16px + 6 * ((15vw - 320px) / 680));
  color:white;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  
}
.imagen-logo{
 width: 30rem;
 height: 25rem;


}

.imagen-logo-articulos{
  width: 30rem;
  height: 25rem;
}
.imagen-rectangular{
  margin: 0 auto;
  padding: 1em 2em;

}

.video-contenidos{
  height: 100%;
  width: 100%;
  min-height: 225px;
  padding: 2rem;
}

.enlaces-pdf{
  display: block;
    text-align: initial;
    margin-left: 2rem;
}
.reglas-j{

  justify-content: center;
  border: 2px solid white;
  border-radius: 20px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  color: black;
  flex-direction: column;

  padding-bottom: 40px;
  width: calc(180% / 2);
  margin: auto;
}

.datos-a{
  padding: 1.5rem;
}
  
@media (min-width: 700px){

  .enlaces-pdf{
    text-align: initial;
    margin-left: 35rem;

  }

  .video-contenidos{
    display: inline-block;
    height: 50%;
    width: 60%;
    min-height: 225px;
    padding: 5rem;
  }

  .imagen-rectangular{
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: calc(90% / 2);
  }
  .imagen-logo{
    max-width: 350px;
    max-height:280px;
    margin-right: 15rem;
   
   
   }
   .imagen-logo-articulos{
    width: 60rem;
    height: auto;
    
}

.texto-rendimiento{
  font-size: calc(16px + 6 * ((15vw - 320px) / 680));
  color:white;
  margin-top: 13rem;
  padding: 2rem;
}
.imagen-rectangular-dentro{
  display: flex;
  justify-content: center;
  border: 2px solid white;
  border-radius: 20px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  color: black;
  flex-direction: column;

  padding-bottom: 40px;
  width: calc(100% / 2);
  margin: auto;
}
.imagen-logo-dentro{
  max-width: 350px;
  max-height:280px;

}
.contenedor-rendimiento{
  display: flex;
  justify-items: center;
  margin: 1rem 1rem 1rem 1rem;
  margin-bottom: 4rem;
  flex-direction: column;
  
}


}
@media (min-width: 1300px){
  

  .imagen-logo{
    width: 65%;
    height: auto;
   
   
   }
   .texto-rendimiento{
    font-size: calc(20px + 6 * ((15vw - 320px) / 680));
    color:white;
    margin-top: 5rem;
  }
  
  .imagen-logo{
    width: 100%;
    height: auto;
    margin-right: 25rem;
   
   
   }

}
.imagen-logo-dentro{
  width: 30rem;
  height: 30rem;
 
 
 }


 @media (min-width:701px){
  .slider-donaciones{
    display: none;
  }

}

/****Fiscalizacion****/
.boton-fiscalizacion{
  display: flex;
  width: 16rem;
  height: auto;
  justify-content: center;
  flex-direction: column;
  margin-right: 0;
  margin-left: 0;

}
.boton-fiscalizacion-carrito{
  display: flex;
  width: 13.3rem;
  height: auto;
  justify-content: center;
  flex-direction: column;
  margin-right: 0;
  margin-left: 1.1rem;

}

.container-fiscalizacion{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.floatingMenu {
  background-color: #3F3F3F;
  z-index:100000;
  display:none;
  width:5rem;
  position: absolute;
  top:0;
  left:0;
}
.tabla{
  color: white;
}
.tabla-fondo{
  background-color: rgb(54, 51, 51);
}

.container-check{


  border: 2px solid grey;
  border-radius: 40px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  color:black;
  padding: 30px 30px 20px;
  margin:2rem;
}
.filter-fiscalizacion{
  justify-content: center;
  border-radius: 1rem;
  border: 0.1rem ;
  font-size: 1.6rem;
  background-color:whitesmoke;
  height: 3.5rem;
  width:15rem;
  display: flex;
  align-items: center;
  margin:4rem;
  width: 80%;
}



.button-red-f{
  font-size: calc(16px + 6 * ((10vw - 320px) / 680));
  background-color:rgb(55, 165, 101);
  border: red;
  border-radius: 1rem;
  color: white;
  cursor: pointer;
  width:30rem;
  height:4rem;
  margin: 0 auto;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}

.range{
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-left: 2rem;
  margin-right: 2rem;
  
}
.input-date{

  justify-content: center;
  border-radius: 1rem;
  border: 0.1rem;
  font-size: 1.6rem;
  background-color: whitesmoke;
  height: 3.5rem;
  width: 15rem;
  display: flex;
  align-items: center;
  width: 80%;
  margin-left: 1rem;
  margin-right: 1rem;


}
@media (min-width: 800px){
  .container-fiscalizacion{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 20rem;
    margin-top: 10rem;
  }
  .filter-fiscalizacion{
    margin:4rem;
    margin-left: 4.4rem;
      width: 93%;
    }

}



/****CARRITO****/
.cart {
  padding: 1rem;
  margin: 1rem;
  display: flex;
  color: #fff;

}

.icon-carrito{
  
  display: flex;
  justify-content: center;


}
.cart-header {
  border-bottom: 0.1rem #c0c0c0 solid;
  display: flex;
  justify-content: center;

 
}
.cart-cart-header{
  border-bottom: 0.1rem #c0c0c0 solid;
  display: flex;
  justify-content: center;
  color: white;
  margin-top: 4rem;
}
.cart-items {
  padding: 0;
  width: 100%;
  list-style-type: none;

}
.cart-items img {
  width: 8rem;
  height: 6rem;

}
.cart-price{
  text-align: center;
}
.cart-price-tachito{
  text-align: center;
  margin-top: 1rem;
}

.cart-items li {
  display: flex;
}
.cart-items li div {
  padding: 0.5rem;
}
.cart-items li div:last-child {
  flex: 1;
}
.cart-button{
  background: none;
  border: none; 

}
.button{
  background: none;
  border: none; 
  margin-left: 4rem;
}
.redes-sociales-basura{
  max-width: 2rem;
  max-height: 3rem;

}
.right {
  text-align: right;
}
.total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.total > div {
  font-size: 2rem;
}
.total > div,
.total > button {
  flex: 1;
}

.imagen-carrito{
display: flex;
margin-top: 1rem;
}
.parrafo-carrito{
  width: 20rem;
  height: 18rem;
}

@media (min-width: 800px){
.homecarrito {
  /* display: flex; */
  margin-left: 10rem;
  margin-right: 10rem;
  padding-left: 10rem;
  padding-right: 10rem;
}
.cart-price{
 margin-left: 13rem;
}
.cart-price-tachito{
  margin-left: 10rem;
}
.parrafo-carrito{
  margin-left: 5rem;
    width: 0rem;
    height: 20rem;
}
}

/*** MIS VIDEOS****/
.videos-lista{
  display: flex;
  flex-direction: row;
justify-content: center;

}
.video{
  margin-left: 3rem;
  margin-right: 3rem;
  display: flex;
  flex-direction: row;
justify-content: center;
margin-top: 2rem;
}
.player{
  margin-top: 5rem;
}
.datos-cursos{
  display: flex;
  flex-direction: column;
  margin-top: 3.5rem;
  margin-left: 1rem;
  justify-content: center;
  font-size: 1.2rem;
}
.p-datos{
  display: flex;
  flex-direction: column;
}
.datos-cursos-container{
  display: flex;
    justify-content: center;
    margin-right: 0.5rem;
}

.reproducir{
  width: 5rem;
  height: 5rem;
  margin-right: 2rem;
  margin-left: 6.5rem;
}

.reproductor{
  background-color:#091335;
  justify-content: center;
  height:100%;
  

}





  .container-videos{
    display: flex;
    justify-content: center;
    border: 2px solid white;
    border-radius: 40px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    color:black;
    flex-direction: column;
    padding-bottom: 2rem;
    width: 90%!important;
   margin-bottom: 10rem;
   margin-top: 5rem;

  }

  .reproducir-descripcion{
    display: flex;
    flex-direction: column;
    margin-left: 3rem;
    margin-right: 20rem;
  }
  .logo-flecha-video{
    margin-top: 1rem;
    width: 5rem;
    height: 4rem;
    margin-right: 1.3rem;
}
  
  .titulo-video{
    display: flex;
    justify-content:space-evenly;
    align-items: center;
   flex-direction: row;

  }
  .lista-v{
    margin-left: 1rem;
    margin-right: 1rem;
  }
/***MEDIOS DE PAGO**/
.m-pagos{
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  margin-right: 1rem;
}
.pagos-personal{
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  margin-right: 1rem;
  width: 20rem;
}

.pagos-paypal{
  display: flex;
  height: 5rem;
  width: 19rem;
  justify-content: center;
  margin-right: 3rem;
  margin-left: 3rem;
  margin-top: 3rem;
}
.pagos-transf{
  background: none;
    border: none;
    display: flex;
    justify-content: center;
    margin-right: 1rem;
    width: 21rem;
    height: 10rem;
    margin-left: 2rem;
}
.pagos-money{
  background: none;
    border: none;
    display: flex;
    justify-content: center;
    margin-right: 1rem;
    width: 25rem;
    height: 7rem;
    margin-left: 0.5rem
}
.container-pagos{

background-color: rgba(255,255,255,0.1);
  border: 2px  rgb(5, 5, 46);
  border-radius: 5px;
  box-shadow: 5px 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  color:black;
  padding-bottom: 2rem
}
/***Projects**/
.datos_tiempo{
  display: flex;
  flex-direction: column;
  margin-top: 0.3rem;
  justify-content: center;


}
.datos-t{
  display: flex;
  justify-content: center;
}
.datos-m{
  font-size: 1.1rem;
}

/*********QUIENES SOMOS**********/



.container-login-form-QS{
  display: flex;
  justify-content: center;
  /* background-color: rgba(255,255,255,0.1); */
  border: 2px solid white;
  border-radius: 5px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  color: black;
  flex-direction: column;
  padding: 5px 5px 5px;
  width: calc(190% / 2);
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1.5rem;
}

.container-qs{
  padding: 1rem;
}

.faqs{
  padding: 2rem;
}

@media (min-width: 700px){
  .container-login-form-QS{
    display: flex;
    justify-content: center;
    border: 2px solid white;
    border-radius: 5px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    color:black;
    flex-direction: column;
    padding: 1px 20px 20px;
    width: calc(190% / 2);
    margin-left: 2rem;
    margin-bottom: 4rem;

  }
  .faqs,.container-qs{
    padding-left: 10rem;
    padding-right: 10rem;
  }
}


/********PASOS**********/


.checkout-steps {
  display: flex;
  justify-content: space-between;
  width: 30rem;
  margin: 1rem auto;
}
.checkout-steps > div {
  border-top: 0.3rem #c0c0c0 solid;
  color: #c0c0c0;
  flex: 1 1;
  padding-top: 1rem;
}
.checkout-steps > div.active {
  border-top: 0.3rem #f08000 solid;
  color: #f08000;
}
/*********HOMEORDER********/
.placeorder-action > ul {
  padding: 0;
  list-style-type: none;
}
.placeorder-action > ul > li {
    display: flex;
    justify-items: center;
    display: inline;
    justify-content: flex-end;
    margin-bottom: 1rem;
    color: white;
    font-size: 1.6rem;
    font-weight: 500;
}

.placeorder-actions-payment > div {
  width: 100%;
}
.order-list{
text-align: left;
padding-left: 1rem;

}
.order-list-resp{
  text-align:center;
padding-left: 1rem;
margin-bottom: 1rem;

}
.tabla-orden{
  display: flex;
  list-style: none;
  color: white;
 
}
.grilla-orden{
  margin:1rem;
  list-style: none;
  color: white;
 
}
.grilla-orden-ico{
 
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

}
.tabla-encabezado{
  display: flex;
}
.compras{
  margin-bottom: 40rem;
}